import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL =
  process.env.NODE_ENV === 'production'
    ? (window as any).env.REACT_APP_USER_MANAGEMENT_API_URL
    : process.env.REACT_APP_USER_MANAGEMENT_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/v1/user/`
export const GET_ATHLETE = `${API_URL}/v1/athlete/`
export const LOGIN_URL = `${API_URL}/v1/auth/signIn`
export const REQUEST_PASSWORD_URL = `${API_URL}/v1/auth/requestPassword`
export const REGISTER_URL = `${API_URL}/v1/auth/signUp`

// Server should return AuthModel
export async function login(email: string, password: string) {
  return await axios.post<AuthModel>(LOGIN_URL, {
    username: email,
    password,
  })
}

export async function getUserByToken(token: string) {
  return await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function register(email: string, firstName: string, lastName: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
  })
}
